import React, {Component} from 'react'
import firebase from "firebase";
import "firebase/storage";


class FireDisplay extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data:[]
    }
    this.getFireData();
  }

  // Firebaseからのデータ取得
  getFireData(){
    let db = firebase.database();
    let ref = db.ref('news/');
    let self = this;
    ref.orderByKey()
      .limitToFirst(1000)
      .on('value', (snapshot)=>{
        self.setState({
          data:snapshot.val()
        });
    });
  }

  // データ表示の生成
  getTableData(){
    let result = [];
    if (this.state.data == null || this.state.data.length == 0){
      return [<li key="0">ニュースがありません</li>];
    }
    for(let i in this.state.data){
      if (this.state.data[i].ID != 1){
        if (this.state.data[i].link == ""){
          result.push(<li className="news__listItem" key={i}>
            <p className="news__listItemDate">{this.state.data[i].date}</p>
            <p className="news__listItemTxt">{this.state.data[i].txt}</p>
          </li>);
        } else {
          if (this.state.data[i].tab == "1"){
            result.push(<li className="news__listItem" key={i}>
              <p className="news__listItemDate">{this.state.data[i].date}</p>
              <a href={this.state.data[i].link} target="_blank" className="news__listItemTxt">{this.state.data[i].txt}</a>
            </li>);
          } else {
            result.push(<li className="news__listItem" key={i}>
              <p className="news__listItemDate">{this.state.data[i].date}</p>
              <a href={this.state.data[i].link} className="news__listItemTxt">{this.state.data[i].txt}</a>
            </li>);
          }
        }
      }
    }
    return result;
  }

  render(){
    if (this.state.data.length == 0){
      this.getFireData();
    }
    return (
      <div className="fireDispay">
        <ul className="news__list">{this.getTableData()}</ul>
      </div>
    )
  }
}


export default FireDisplay;