import React, { Component } from 'react';
import Home from './page/Home'
import Menu from './page/Menu'
import ShopInfo from './page/ShopInfo'
import News from './page/News'

// Layoutコンポーネント
class Layout extends Component {

  constructor(props){
    super(props);
    this.state = {
      on01: "on",
      on02: "",
      on03: "",
      on04: "",
      homeflg: true,
      menuflg: false,
      infoflg: false,
      newsflg: false
    }
    this.doAction01 = this.doAction01.bind(this);
    this.doAction02 = this.doAction02.bind(this);
    this.doAction03 = this.doAction03.bind(this);
    this.doAction04 = this.doAction04.bind(this);
  }

  doAction01(e){
    if (this.state.on01 == "") {
      this.setState({
        on01: "on",
        on02: "",
        on03: "",
        on04: "",
        homeflg: true,
        menuflg: false,
        infoflg: false,
        newsflg: false
      });
    }
  }
  doAction02(e){
    if (this.state.on02 == "") {
      this.setState({
        on01: "",
        on02: "on",
        on03: "",
        on04: "",
        homeflg: false,
        menuflg: true,
        infoflg: false,
        newsflg: false
      });
    }
  }
  doAction03(e){
    if (this.state.on03 == "") {
      this.setState({
        on01: "",
        on02: "",
        on03: "on",
        on04: "",
        homeflg: false,
        menuflg: false,
        infoflg: true,
        newsflg: false
      });
    }
  }
  doAction04(e){
    if (this.state.on04 == "") {
      this.setState({
        on01: "",
        on02: "",
        on03: "",
        on04: "on",
        homeflg: false,
        menuflg: false,
        infoflg: false,
        newsflg: true
      });
    }
  }


  render() {
    return (
      <div>
        <div className="pc">
          <Home />
          <Menu />
          <ShopInfo />
          <News />
          <p className="copyright">Copyright © スムージーカフェ菜菜 All Rights Resercved.</p>
        </div>
        <div className="sp">
          {this.state.homeflg && 
            <Home />
          }
          {this.state.menuflg && 
            <Menu />
          }
          {this.state.infoflg && 
            <ShopInfo />
          }
          {this.state.newsflg && 
            <News />
          }
        </div>
        <div className="menuBtn sp">
          <ul className="menuBtn__list">
            <li onClick={this.doAction01} className={"menuBtn__listItem " + this.state.on01}>
              <div className="menuBtn__listItemIconWrap">
                <img className="menuBtn__listItemIcon menuBtn__listItemIcon__01" src="img/icon/icon_home_01.svg" />
                <img className="menuBtn__listItemIcon menuBtn__listItemIcon__02" src="img/icon/icon_home_02.svg" />
              </div>
              <p className="menuBtn__listItemTxt">Home</p>
            </li>
            <li onClick={this.doAction02} className={"menuBtn__listItem " + this.state.on02}>
              <div className="menuBtn__listItemIconWrap">
                <img className="menuBtn__listItemIcon menuBtn__listItemIcon__01" src="img/icon/icon_menu_01.svg" />
                <img className="menuBtn__listItemIcon menuBtn__listItemIcon__02" src="img/icon/icon_menu_02.svg" />
              </div>
              <p className="menuBtn__listItemTxt">Menu</p>
            </li>
            <li onClick={this.doAction03} className={"menuBtn__listItem " + this.state.on03}>
              <div className="menuBtn__listItemIconWrap">
                <img className="menuBtn__listItemIcon menuBtn__listItemIcon__01" src="img/icon/icon_info_01.svg" />
                <img className="menuBtn__listItemIcon menuBtn__listItemIcon__02" src="img/icon/icon_info_02.svg" />
              </div>
              <p className="menuBtn__listItemTxt">Shop Info</p>
            </li>
            <li onClick={this.doAction04}  className={"menuBtn__listItem " + this.state.on04}>
              <div className="menuBtn__listItemIconWrap">
                <img className="menuBtn__listItemIcon menuBtn__listItemIcon__01" src="img/icon/icon_news_01.svg" />
                <img className="menuBtn__listItemIcon menuBtn__listItemIcon__02" src="img/icon/icon_news_02.svg" />
              </div>
              <p className="menuBtn__listItemTxt">News</p>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}


export default Layout;