import React, { Component } from 'react';
import FireDisplay from '../fire/FireDisplay';
import firebase from "firebase";

// Firebaseの設定
var config = {
  apiKey: "AIzaSyB0NJufUR_WgBNk3TsXpm7Kc-ZL0F_NQqU",
  authDomain: "nana-obifarm.firebaseapp.com",
  databaseURL: "https://nana-obifarm.firebaseio.com",
  projectId: "nana-obifarm",
  storageBucket: "nana-obifarm.appspot.com",
  messagingSenderId: "174131292641",
  appId: "1:174131292641:web:0caabccefd57305b3b0aa0"
};

// Firebaseの初期化
firebase.initializeApp(config);

// Newsコンポーネント
class News extends Component {

  constructor(props){
    super(props);
  }

  render() {
    return (
      <div className="news">
        <div className="header">
          <h1 className="header__title">News</h1>
        </div>
        {/* <ul className="news__list">
          <li className="news__listItem">
            <p className="news__listItemDate">2020.05.11</p>
            <p className="news__listItemTxt">本日より営業を再開いたしました。</p>
          </li>
          <li className="news__listItem">
            <p className="news__listItemDate">2020.04.25</p>
            <p className="news__listItemTxt">臨時休業の期間を以下のように延長することにいたしました。<br />4月14日(火)〜5月10日(日)</p>
          </li>
          <li className="news__listItem">
            <p className="news__listItemDate">2020.04.13</p>
            <p className="news__listItemTxt">新型コロナウイルス感染拡大防止のため、以下の期間を臨時休業することにいたしました。<br />4月14日(火)〜4月28日(火)</p>
          </li>
          <li className="news__listItem">
            <p className="news__listItemDate">2019.12.25</p>
            <p className="news__listItemTxt">年末年始の休業日のお知らせ<br />12月30(月)〜1月5日(日)</p>
          </li>
        </ul> */}
        <FireDisplay />
      </div>
    );
  }
}


export default News;