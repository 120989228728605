import React, { Component } from 'react';

// Menuコンポーネント
class Menu extends Component {

  constructor(props){
    super(props);
  }

  render() {

    let gs = [
      {img:'green_smoothie_01', title:'菜菜スムージー', price:'¥500', txt:'季節の野菜・バナナ・みかん'},
      {img:'green_smoothie_02', title:'トマトスムージー', price:'¥500', txt:'季節の野菜・トマト・バナナ'},
      {img:'green_smoothie_03', title:'キウイスムージー', price:'¥500', txt:'季節の野菜・キウイ・りんご'},
      {img:'green_smoothie_04', title:'みかんとりんごのスムージー', price:'¥500', txt:'季節の野菜・みかん・りんご'},
    ];
    let fs = [
      {img:'fruits_smoothie_01', title:'いちごミルクスムージー', price:'¥600', txt:'いちご・レモン・ミルク'},
      {img:'fruits_smoothie_02', title:'マンゴーミルクスムージー', price:'¥700', txt:'マンゴー・レモン・ミルク'},
      {img:'fruits_smoothie_03', title:'日向夏ぶんたんスムージー', price:'¥600', txt:'日向夏・ぶんたん'},
      // {img:'fruits_smoothie_04', title:'みかんスムージー', price:'¥600', txt:'みかん'},
      // {img:'fruits_smoothie_05', title:'ブルーベリーミルクスムージー', price:'¥650', txt:'ブルーベリー・レモン・パイナップル・ミルク'},
    ];
    let fd = [
      {img:'food_01', title:'ナポリタン', price:'¥600'},
      {img:'food_02', title:'カルボナーラ', price:'¥600'},
      {img:'food_03', title:'えびピラフ', price:'¥600'},
      {img:'food_04', title:'オムライス', price:'¥600'},
    ];
    let ss = [
      // {img:'sweets_01', title:'ガトーショコラ', price:'¥300'},
      {img:'sweets_02', title:'さつまいもプリンケーキ', price:'¥300'},
      // {img:'sweets_03', title:'厚焼きスフレパンケーキ', price:'¥500'},
      {img:'sweets_04', title:'プチかぼちゃケーキ', price:'¥100'},
      {img:'sweets_05', title:'かぼちゃクッキー', price:'¥150'},
      {img:'sweets_06', title:'バニラアイス', price:'¥200'},
    ];

    return (
      <div className="menu">

        <div className="header">
          <h1 className="header__title">Menu</h1>
        </div>

        <div className="menu__content">
          <h2 className="menu__title">Green Smoothie</h2>
          <ul className="menu__list">
              {gs.map((value, i) =>(
              <li className="menu__listItem" key={i}>
                  <img className="menu__listItemImg" src={'img/menu/' + value.img + '.jpg'} alt=""></img>
                  <h3 className="menu__listItemTitle">{value.title}</h3>
                  <p className="menu__listItemPrice">{value.price}</p>
                  <p className="menu__listItemTxt">{value.txt}</p>
              </li>
              ))}
          </ul>
        </div>

        <div className="menu__content">
          <h2 className="menu__title">Fruits Smoothie</h2>
          <ul className="menu__list">
              {fs.map((value, i) =>(
              <li className="menu__listItem" key={i}>
                  <img className="menu__listItemImg" src={'img/menu/' + value.img + '.jpg'} alt=""></img>
                  <h3 className="menu__listItemTitle">{value.title}</h3>
                  <p className="menu__listItemPrice">{value.price}</p>
                  <p className="menu__listItemTxt">{value.txt}</p>
              </li>
              ))}
          </ul>
        </div>

        <div className="menu__content">
          <h2 className="menu__title">Food</h2>
          <ul className="menu__list">
              {fd.map((value, i) =>(
              <li className="menu__listItem" key={i}>
                  <img className="menu__listItemImg" src={'img/menu/' + value.img + '.jpg'} alt=""></img>
                  <h3 className="menu__listItemTitle">{value.title}</h3>
                  <p className="menu__listItemPrice">{value.price}</p>
              </li>
              ))}
          </ul>
        </div>

        <div className="menu__content">
          <h2 className="menu__title">Sweets</h2>
          <ul className="menu__list">
              {ss.map((value, i) =>(
              <li className="menu__listItem" key={i}>
                  <img className="menu__listItemImg" src={'img/menu/' + value.img + '.jpg'} alt=""></img>
                  <h3 className="menu__listItemTitle">{value.title}</h3>
                  <p className="menu__listItemPrice">{value.price}</p>
              </li>
              ))}
          </ul>
        </div>

        <div className="menu__content">
          <h2 className="menu__title">Drink</h2>
          <ul className="menu__list menu__list_drink">
            <li className="menu__listItem">
              <p className="menu__listItemTitle">アイスコーヒー</p>
              <p className="menu__listItemPrice">¥330</p>
              <p className="menu__listItemTitle">ブレンドコーヒー</p>
              <p className="menu__listItemPrice">¥330</p>
              <p className="menu__listItemTitle">アメリカンコーヒー</p>
              <p className="menu__listItemPrice">¥330</p>
              <p className="menu__listItemTitle">ソフトエスプレッソ</p>
              <p className="menu__listItemPrice">¥330</p>
            </li>
            <li className="menu__listItem">
              <p className="menu__listItemTitle">紅茶</p>
              <p className="menu__listItemPrice">¥330</p>
              <p className="menu__listItemTitle">レモンティー</p>
              <p className="menu__listItemPrice">¥330</p>
              <p className="menu__listItemTitle">ミルクティー</p>
              <p className="menu__listItemPrice">¥330</p>
            </li>
            <li className="menu__listItem">
              <p className="menu__listItemTitle">レモンスカッシュ</p>
              <p className="menu__listItemPrice">¥420</p>
              <p className="menu__listItemTitle">梅ソーダ</p>
              <p className="menu__listItemPrice">¥420</p>
              <p className="menu__listItemTitle">梅ジュース</p>
              <p className="menu__listItemPrice">¥370</p>
            </li>
            <li className="menu__listItem">
              <p className="menu__listItemTitle">赤しそミルク</p>
              <p className="menu__listItemPrice">¥420</p>
              <p className="menu__listItemTitle">赤しそソーダ</p>
              <p className="menu__listItemPrice">¥420</p>
              <p className="menu__listItemTitle">赤しそジュース</p>
              <p className="menu__listItemPrice">¥370</p>
            </li>
          </ul>
        </div>

      </div>
    );
  }
}


export default Menu;