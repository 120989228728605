import React, { Component } from 'react';
import './App.sass';
import './reset.sass';
import DrawerBtn from './DrawerBtn'
import Layout from './Layout'


// Appコンポーネント
class App extends Component {
  constructor(props){
    super(props);
  }

  render() {
    return (
      <div>
        {/* <DrawerBtn /> */}
        <Layout />
      </div>
    );
  }
}

export default App;
