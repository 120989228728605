import React, { Component } from 'react';

// ShopInfoコンポーネント
class ShopInfo extends Component {

  constructor(props){
    super(props);
  }

  render() {
    return (
      <div className="shopInfo">
        <div className="header">
          <h1 className="header__title">Shop Info</h1>
        </div>
        <ul className="shopInfo__list">
          <li className="shopInfo__listItem">
            <p className="shopInfo__listItemLabel">店名</p>
            <p className="shopInfo__listItemTxt">スムージーカフェ菜菜</p>
          </li>
          <li className="shopInfo__listItem">
            <p className="shopInfo__listItemLabel">郵便番号</p>
            <p className="shopInfo__listItemTxt">889-2535</p>
          </li>
          <li className="shopInfo__listItem">
            <p className="shopInfo__listItemLabel">住所</p>
            <p className="shopInfo__listItemTxt">宮崎県日南市飫肥5丁目3-36</p>
          </li>
          <li className="shopInfo__listItem">
            <p className="shopInfo__listItemLabel">電話番号</p>
            <p className="shopInfo__listItemTxt">0987-25-1277</p>
          </li>
          <li className="shopInfo__listItem">
            <p className="shopInfo__listItemLabel">営業時間</p>
            <p className="shopInfo__listItemTxt">10：30〜16：00</p>
          </li>
          <li className="shopInfo__listItem">
            <p className="shopInfo__listItemLabel">定休日</p>
            <p className="shopInfo__listItemTxt">火曜日</p>
          </li>
        </ul>
      </div>
    );
  }
}


export default ShopInfo;