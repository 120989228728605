import React, { Component } from 'react';

// DrawerBtnコンポーネント
class DrawerBtn extends Component {

  constructor(props){
    super(props);
    this.state = {
      on: ""
    }
    this.doAction = this.doAction.bind(this);
  }

  doAction(e){
    if (this.state.on == "") {
      this.setState({
        on: "on"
      });
    } else {
      this.setState({
        on: ""
      });
    }
  }

  render() {
    return (
      <div>
        <div onClick={this.doAction} className={"drawerBtn " + this.state.on}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }
}


export default DrawerBtn;