import React, { Component } from 'react';

// Homeコンポーネント
class Home extends Component {

  constructor(props){
    super(props);
  }

  render() {
    const bg = {
      backgroundImage: ("img/home/mv_sp.jpg")
    };
    return (
      <div className="home">
        <div className="header">
          <h1 className="header__title">Smoothie Cafe NaNa</h1>
        </div>
        <div className="home__content">
          <div className="home__box">
            <img className="home__img home__img_01" src={'img/home/mv_01.jpg'} alt="" />
            <img className="home__img home__img_02" src={'img/home/mv_02.jpg'} alt="" />
            <img className="home__img home__img_03" src={'img/home/mv_03.jpg'} alt="" />
            <img className="home__img home__img_04" src={'img/menu/sweets_04.jpg'} alt="" />
            <img className="home__img home__img_05" src={'img/menu/sweets_02.jpg'} alt="" />
            <img className="home__img home__img_06" src={'img/home/mv_04.jpg'} alt="" />
          </div>
          <p className="home__txt">観光地として賑わいのある、宮崎県日南市の飫肥城近くで営業しております。<br />自社で採れた野菜と果物中心に新鮮な素材を使って手作りでご提供しております。<br />スムージー以外にも、フルーツを使ったジュースやコーヒー、スイーツやランチなどもございます。<br />飫肥にお越しの際はぜひ休憩にお立ち寄りくださいませ。</p>
          </div>
          <a className="home__instagram" href="https://www.instagram.com/smoothiecafe_nana/" target="_blank">
            <img className="home__instagramIcon" src="img/icon/icon_instagram_01.svg" />
            <p className="home__instagramTxt">Instagram</p>
          </a>
      </div>
    );
  }
}


export default Home;